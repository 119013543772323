<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ guideLedger.no }}
        </el-form-item>
        <el-form-item v-if="guideLedger.updateDate" label="修改时间:">
          {{ guideLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="guideLedger.updateUserName" label="最后修改人:">
          {{ guideLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          农药安全使用指导记录
        </caption>
        <tbody>
          <tr>
            <td>时间</td>
            <td colspan="2" style="text-align: left">
              <el-date-picker
                v-model="guideLedger.recordDate"
                :disabled="isView"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td colspan="2">本店指导人员姓名</td>
            <td colspan="4">
              <el-input
                style="width: 100%"
                v-model="guideLedger.guidePerson"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>购买人姓名</td>
            <td>
              <el-input
                style="width: 100%"
                v-model="guideLedger.purchasePerson"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
            <td>购买人电话</td>
            <td>
              <el-input
                style="width: 100%"
                v-model="guideLedger.purchaseContact"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
            <td>购买人住址</td>
            <td colspan="3">
              <el-input
                style="width: 100%"
                v-model="guideLedger.purchaseAddress"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="8" style="font-size: 20px; font-weight: 600">
              病虫害防治或发生情况（必要时实地查看）简要描述
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <el-input
                style="width: 100%"
                v-model="guideLedger.easyDescribe"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="8" style="font-size: 20px; font-weight: 600">
              推荐使用的农药名称及使用说明指导事项
            </td>
          </tr>
          <tr>
            <td>农药名称</td>
            <td>
              <el-select
                v-model="guideLedger.productName"
                style="width: 100%"
                allowCreate
                filterable
                clearable
                placeholder="请选择"
                @change="productChange"
              >
                <el-option
                  v-for="(item, Index) in products"
                  :key="Index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </td>
            <td>农药剂型</td>
            <td>
              <el-select
                v-model="guideLedger.dosage"
                style="width: 100%"
                allowCreate
                filterable
                clearable
                placeholder="请选择检查位置"
              >
                <el-option
                  v-for="(item, Index) in dosageList"
                  :key="Index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
            <td>农药毒性</td>
            <td>
              <el-select
                v-model="guideLedger.toxicity"
                style="width: 100%"
                allowCreate
                filterable
                clearable
                placeholder="请选择检查位置"
              >
                <el-option
                  v-for="(item, Index) in toxicityList"
                  :key="Index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
            <td>限制性农药</td>
            <td>
              <el-radio-group v-model="guideLedger.isLimit">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2">使（适）用范围</td>
            <td colspan="6">
              <el-input
                style="width: 100%"
                v-model="guideLedger.applyRange"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="2">使用方法及剂量</td>
            <td colspan="6">
              <el-input
                style="width: 100%"
                v-model="guideLedger.effectiveContent"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="2">使用技术要求</td>
            <td colspan="6">
              <el-input
                style="width: 100%"
                v-model="guideLedger.demand"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="2">注意事项</td>
            <td colspan="6">
              <el-input
                style="width: 100%"
                v-model="guideLedger.noticeItem"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="2">中毒急救措施</td>
            <td colspan="6">
              <el-input
                style="width: 100%"
                v-model="guideLedger.toxicityMeasure"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="2">其他需要说明的事项</td>
            <td colspan="6">
              <el-input
                style="width: 100%"
                v-model="guideLedger.explainOther"
                rows="4"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="8" style="font-size: 20px; font-weight: 600">
              农药安全使用指导告知事项
            </td>
          </tr>
          <tr>
            <td colspan="8" style="text-align: left">
              <div>
                <el-checkbox-group
                  v-model="checkSource"
                  @change="checkSourceChange"
                >
                  <div
                    style="margin-top: 10px"
                    v-for="source in safeItems"
                    :key="source.name"
                  >
                    <el-checkbox
                      :disabled="isView"
                      :label="source.name"
                    ></el-checkbox>
                  </div>
                  <div style="margin-top: 10px">
                    <el-checkbox
                      :disabled="isView"
                      label="13、其他告知事项"
                    ></el-checkbox>
                    <el-input
                      style="width: 100%"
                      v-model="sourceExtra"
                      :disabled="
                        isView || checkSource.indexOf('13、其他告知事项') == -1
                      "
                      type="text"
                    ></el-input>
                  </div>
                </el-checkbox-group>
              </div>
              <div style="margin-top: 20px">
                <el-row
                  style="height: 100px; display: flex; align-items: center"
                >
                  <el-col
                    :span="12"
                    style="text-align: left; display: flex; align-items: center"
                  >
                    <span>被指导农药购买使用人签名:</span>
                    <div style="display: inline-block">
                      <div v-if="isView">
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="getImg(guideLedger.sign)"
                        ></el-image>
                      </div>
                      <div v-else @click="openSign">
                        <div v-if="!guideLedger.sign" class="sign">
                          点击签名
                        </div>
                        <el-image
                          v-else
                          style="width: 100px; height: 100px"
                          :src="getImg(guideLedger.sign)"
                        ></el-image>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12" style="text-align: right">
                    <span>指导时间:</span>
                    <el-date-picker
                      v-model="guideLedger.guideDate"
                      :disabled="isView"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-col>
                </el-row>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import { GetProductList, getDetails } from "@/api/pesticideProduct";
import {
  getGuideLedgerCode,
  getGuideLedgerDetails,
  addGuideLedger,
  editGuideLedger,
} from "@/api/guideLedger";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      guideLedger: {
        isLimit: "0",
      },
      products: [],
      isView: false,
      signatureVisible: false,
      toxicityList: [
        //毒性列表
        {
          id: "剧毒",
          name: "剧毒",
        },
        {
          id: "高毒",
          name: "高毒",
        },
        {
          id: "中等毒",
          name: "中等毒",
        },
        {
          id: "低毒",
          name: "低毒",
        },
        {
          id: "微毒",
          name: "微毒",
        },
      ],
      dosageList: [
        //剂型列表
        {
          id: "乳油",
          name: "乳油",
        },
        {
          id: "悬浮剂",
          name: "悬浮剂",
        },
        {
          id: "可湿性粉剂",
          name: "可湿性粉剂",
        },
        {
          id: "粉剂",
          name: "粉剂",
        },
        {
          id: "粒剂",
          name: "粒剂",
        },
        {
          id: "水剂",
          name: "水剂",
        },
        {
          id: "毒饵",
          name: "毒饵",
        },
        {
          id: "母液",
          name: "母液",
        },
        {
          id: "母粉",
          name: "母粉",
        },
      ],
      safeItems: [
        {
          name: "1、本店不经营禁用农药，保证绝不向购买人推荐使用禁用农药或含有禁用农药隐性成分的农药产品",
          value: "",
        },
        {
          name: "2、本店不具有限制使用农药定点经营资格，保证绝不向购买人推荐使用限用农药或含有限用农药隐性成分的农药产品",
          value: "",
        },
        {
          name: "3、本店有合法限制使用农药定点经营资格，现郑重告知购买使用人不得将限制使用农药用于禁止使用的用途和作物",
          value: "",
        },
        {
          name: "4、在使用农药前，请严格按照农药包装标签和使用说明标注的使用范围、使用方法和剂量、使用技术要求和注意事项使用农药，不得扩大使用范围、加大用药剂量或者改变使用方法",
          value: "0",
        },
        {
          name: "5、农药使用安全间隔期内禁止用药，最后一次施药至作物采收请严格执行安全间隔期规定",
          value: "0",
        },
        {
          name: "6、农药使用安全间隔期内禁止用药，最后一次施药至作物采收请严格执行安全间隔期规定",
          value: "0",
        },
        {
          name: "7、炎热或大风天气不可施药",
          value: "0",
        },
        {
          name: "8、不同类别农药的施药器具不可混用，以防发生药害事故",
          value: "0",
        },
        {
          name: "9、不同类别农药的施药器具不可混用，以防发生药害事故",
          value: "0",
        },
        {
          name: "10、施药后，请在施药区域设立明显的警示危险标识",
          value: "0",
        },
        {
          name: "11、施药后，施药人员在未进行彻底清洗之前请勿吸烟或吃东西",
          value: "0",
        },
        {
          name: "12、施药后，产生的农药包装废弃物禁止随意丢弃，请妥善收集，及时交回销售方回收处理",
          value: "0",
        },
      ],
      checkSource: [],
      sourceExtra: "",
    };
  },
  mounted() {
    this.guideLedger.recordDate = moment().format("YYYY-MM-DD");
    this.guideLedger.guideDate = moment().format("YYYY-MM-DD");
  },
  methods: {
    init(id, view) {
      this.getProductList();
      if (id) {
        getGuideLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.guideLedger = res.data;

            this.safeItems = JSON.parse(res.data.guideText);
            this.isView = view;
          }
        });
      } else {
        getGuideLedgerCode().then((res) => {
          if (res.code == 1) {
            this.guideLedger = {
              ...this.guideLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getProductList() {
      GetProductList({ pageNum: 1, pageSize: 500 }).then((res) => {
        if (res.code == 1) {
          this.products = res.data.records;
        }
      });
    },
    productChange(val) {
      // 农药商品变厚查询数据库是否存在
      const reuslts = this.products.filter((v) => v.name == val);
      if (reuslts.length > 0) {
        getDetails({ productId: reuslts[0].id }).then((res) => {
          if (res.code === 1) {
            this.guideLedger = {
              ...this.guideLedger,
              dosage: res.data.dosage,
              toxicity: res.data.toxicity,
              isLimit: res.data.isLimit,
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "1") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    back() {
      this.$emit("back");
    },
    checkSourceChange(val) {
      this.checkSource = val;
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.guideLedger.guidePerson) {
        return this.$message.error("请填写指导人");
      }
      if (!this.guideLedger.purchasePerson) {
        return this.$message.error("请填写购买人");
      }
      if (!this.guideLedger.purchaseContact) {
        return this.$message.error("请填写购买人电话");
      }
      if (!this.guideLedger.purchaseAddress) {
        return this.$message.error("请填写购买人地址");
      }
      if (!this.guideLedger.productName) {
        return this.$message.error("请填写农药名称");
      }
      if (!this.guideLedger.dosage) {
        return this.$message.error("请选择剂型");
      }
      if (!this.guideLedger.toxicity) {
        return this.$message.error("请选择毒性");
      }
      if (!this.guideLedger.toxicity) {
        return this.$message.error("请选择毒性");
      }

      this.guideLedger.guideText = JSON.stringify(this.safeItems);
      if (this.guideLedger.id) {
        editGuideLedger(this.guideLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addGuideLedger(this.guideLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.guideLedger = { ...this.guideLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
